<template>
  <div>
    <div class="detail">

      <div class="search">
        <!-- <el-select v-model="eligibleType" placeholder="全部状态" @change="onSearch" clearable>
          <el-option v-for="item in eligibleTypes" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <el-input v-model="tendersName" placeholder="搜索名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="dialogVisible = true">新增</el-button>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%;" max-height="540">
          <el-table-column label="序号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="eligibleName" label="名称" show-overflow-tooltip></el-table-column>
          <el-table-column label="资格类型">
            <template slot-scope="scope">
              <span>{{ scope.row.type == 1 ? '独标资格' : '平台资格' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="eligiblePrice" label="金额"></el-table-column>
          <el-table-column prop="tendersCount" label="可投标商品次数(次)" min-width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.tendersCount == -1 ? '不限' : scope.row.tendersCount }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="规则有效期">
                        <template slot-scope="scope">
                            <span>{{ scope.row.startTime }}至{{ scope.row.endTime }}</span>
                        </template>
                    </el-table-column> -->
          <el-table-column prop="validDate" label="资格有效期（天）" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.validDate }}天
            </template>
          </el-table-column>
          <!-- <el-table-column prop="createdTime" label="状态">
            <template slot-scope="scope">
              <span style="color: #EB2500;" v-if="scope.row.status == 0">未生效</span>
              <span style="color: #0096FF;" v-if="scope.row.status == 1">生效</span>
              <span style="color: #707070;" v-if="scope.row.status == 2">失效</span>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="danger" plain @click="deletetableData(scope.row)" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
      </el-pagination>
    </div>
    <el-dialog title="新增" :visible.sync="dialogVisible" width="80%" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="period">
              <el-input v-model="ruleForm.period" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item label="可投标商品次数" prop="num">
              <el-radio-group v-model="ruleForm.num">
                <el-radio :label="0">不限次数</el-radio>
                <el-radio :label="1">限制次数</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="投标次数" v-if="ruleForm.num == 1" prop="tendersCount">
              <el-input-number v-model="ruleForm.tendersCount" :precision="0" :min="0" label="请输入投标次数"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应标资格金额" prop="radio">
              <el-input-number v-model="ruleForm.radio" :precision="2" :min="1" label="请输入金额"></el-input-number>
            </el-form-item>
            <!-- <el-form-item label="规则有效时间（天）" prop="applicationEndTime">
                            <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="ruleForm.applicationEndTime" value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item> -->
            <el-form-item label="资格有效期（天）" prop="bidNumber">
              <el-input-number v-model="ruleForm.bidNumber" :precision="0" :min="1" label="请输入天数"></el-input-number>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data () {
    return {
      dialogVisible: false,
      tendersNumber: "", //搜索标号
      tendersName: "", //搜索招标名称
      supplierName: "", //搜索供应商名称
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      eligibleType: "",
      eligibleTypes: [
        {
          value: "0",
          label: "未生效",
        },
        {
          value: "1",
          label: "生效",
        },
        {
          value: "2",
          label: "失效",
        },
      ],
      tableData: [],
      rules: {
        radio: [
          {
            required: true,
            message: "请输入金额",
            trigger: "submit",
          },
        ],
        period: [
          {
            required: true,
            message: "请输入名称",
            trigger: "submit",
          },
        ],
        bidNumber: [
          {
            required: true,
            message: "请输入天数",
            trigger: "submit",
          },
        ],
        // applicationEndTime: [
        //     {
        //         required: true,
        //         message: "请选择日期",
        //         trigger: "submit",
        //     },
        // ],
        num: [
          {
            required: true,
            message: "请选择可投标商品次数",
            trigger: "submit",
          },
        ],
        tendersCount: [
          {
            required: true,
            message: "请输入可投标商品次数",
            trigger: "submit",
          },
        ],
      },
      ruleForm: {
        radio: "", //应标资格金额
        period: "", //标期
        bidNumber: 1, //天数
        // applicationEndTime: "",
        num: 0,
        tendersCount: 0,
      },
    };
  },
  created () {
    this.getProductList();
  },
  methods: {
    deletetableData (item) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.tendersEligibleAudit, {
              id: item.id,
            })
            .then((res) => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc);
                this.currentPage = 1;
                this.tableData = [];
                this.getProductList();
              }
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    // 商品列表
    getProductList () {
      this.$axios
        .get(this.$api.tendersEligible, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            eligibleName: this.tendersName || null,
            status: this.eligibleType || null,
          },
        })
        .then((res) => {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        })
        .catch(() => { });
    },

    // 换页
    handleCurrentChange () {
      this.page += 1;
      this.tableData = [];
      this.getProductList();
    },
    // 搜索
    onSearch () {
      this.currentPage = 1;
      this.tableData = [];
      this.getProductList();
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e;
      this.tableData = [];
      this.onSearch();
    },
    submitForm (formName) {
      if (this.ruleForm.num == 0) {
        this.ruleForm.tendersCount = -1;
      }
      let { radio, period, bidNumber, tendersCount } = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(this.$api.tendersEligibleAdd, {
              eligibleName: period,
              eligiblePrice: radio,
              validDate: bidNumber,
              tendersCount: tendersCount,
              // startTime: applicationEndTime[0],
              // endTime: applicationEndTime[1],
            })
            .then((res) => {
              if (res.data.code == 100) {
                this.getProductList();
                this.onCancel();
                this.$message.success(res.data.desc);
              }
            })
            .catch((err) => {
              this.onCancel();
              this.$message.error(err.data.desc);
            });
        } else {
          this.$message.error("提交失败");
          return false;
        }
      });
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>

<style lang="scss">
.el-input-number {
  width: 254px;
}

::v-deep .el-range-input {
  background: #f2f2f2;
  border-radius: 10px;
  border: none;
}

.detail {
  padding: 20px;
  // border-radius: 6px;
  // border: 1px solid #e2e2e2;

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin-left: 20px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  // /* 设置表格单元格的最大宽度和省略显示 */
  // ::v-deep .el-table__row td .cell {
  //   max-width: 200px;
  //   /* 根据需要设置最大宽度 */
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
}
</style>